import { useState, useRef, useEffect } from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import Navbar from "../../components/Navbar";
import { allFirstDiv } from "../../components/DesignStandardize";
import PageTitle from "../../components/PageTitle";
const AttendanceReportSummary = () => {
  // Initialize with current date
  const currentDate = new Date()
  const [year, setYear] = useState(currentDate.getFullYear().toString())
  const [month, setMonth] = useState((currentDate.getMonth() + 1).toString())
  const [reportData, setReportData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const apiUrl = process.env.REACT_APP_API_BASE_URL

  const handleYearChange = (e) => setYear(e.target.value)
  const handleMonthChange = (e) => setMonth(e.target.value)

  const fetchReport = async () => {
    if (!year || !month) {
      alert("Please select both year and month")
      return
    }

    setLoading(true)
    try {
      const response = await fetch(`${apiUrl}/attendance/monthly-report/${year}/${month}`)
      if (!response.ok) {
        throw new Error("Failed to fetch data")
      }
      const data = await response.json()
      setReportData(data)
    } catch (error) {
      console.error("Error fetching report:", error)
      setReportData({ report: [] })
    } finally {
      setLoading(false)
    }
  }

  const exportCSV = () => {
    if (!reportData || !reportData.report.length) return

    const headers = Object.keys(reportData.report[0]).join(",")
    const csv = [headers, ...reportData.report.map((row) => Object.values(row).join(","))].join("\n")

    downloadFile(csv, "attendance_report.csv", "text/csv")
  }

  const exportJSON = () => {
    if (!reportData) return
    const json = JSON.stringify(reportData, null, 2)
    downloadFile(json, "attendance_report.json", "application/json")
  }

  const exportPDF = () => {
    if (!reportData || !reportData.report.length) return

    const doc = new jsPDF()

    // Add title
    doc.setFontSize(16)
    doc.text("Monthly Attendance Report", 14, 15)

    // Add report details
    doc.setFontSize(10)
    doc.text(`Year: ${year} | Month: ${month}`, 14, 25)

    const headers = [
      "Employee ID",
      "Name",
      "Total Days",
      "Working Days",
      "Present Days",
      "Absent Days",
      "Leave Days",
      "Fridays",
      "Holidays",
      "Late Days",
      "Late Absences",
      "Days With Tasks",
    ]

    const data = reportData.report.map((row) => Object.values(row))

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      theme: "grid",
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
      headStyles: {
        fillColor: [45, 45, 45],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    })

    doc.save("attendance_report.pdf")
  }

  const downloadFile = (content, fileName, contentType) => {
    const a = document.createElement("a")
    const file = new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
    URL.revokeObjectURL(a.href)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <>
    <Navbar />

      <PageTitle title="Dashboard" />
    <div className="min-h-screen bg-[#F5F5F5] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8">
        <h1 className="text-4xl font-extrabold text-[#2D2D2D] text-center">Monthly Attendance Report</h1>

        {/* Form Card */}
        <div className="bg-white rounded-2xl overflow-hidden border border-gray-200 shadow-[0_8px_30px_rgb(0,0,0,0.12)] backdrop-blur-sm">
          <div className="p-6 sm:p-8">
            <h2 className="text-xl font-semibold text-[#2D2D2D] mb-6">Select Period</h2>
            <div className="flex flex-col sm:flex-row gap-6">
              <div className="flex-1 space-y-2">
                <label htmlFor="year" className="block text-sm font-medium text-gray-700">
                  Year
                </label>
                <input
                  id="year"
                  type="number"
                  placeholder="Year (e.g., 2025)"
                  value={year}
                  onChange={handleYearChange}
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#2D2D2D] focus:border-transparent transition duration-200 ease-in-out text-gray-700 placeholder-gray-400 bg-white"
                />
              </div>
              <div className="flex-1 space-y-2">
                <label htmlFor="month" className="block text-sm font-medium text-gray-700">
                  Month
                </label>
                <select
                  id="month"
                  value={month}
                  onChange={handleMonthChange}
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#2D2D2D] focus:border-transparent transition duration-200 ease-in-out text-gray-700 appearance-none bg-white"
                >
                  <option value="">Select month</option>
                  {[...Array(12)].map((_, i) => (
                    <option key={i + 1} value={(i + 1).toString()}>
                      {new Date(0, i).toLocaleString("default", { month: "long" })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex items-end">
                <button
                  onClick={fetchReport}
                  disabled={loading}
                  className="w-full px-6 py-3 bg-[#2D2D2D] text-white rounded-xl hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2D2D2D] transition duration-200 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed shadow-lg"
                >
                  {loading ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Loading...
                    </span>
                  ) : (
                    "Get Report"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Report Card */}
        {reportData && (
          <div className="bg-white rounded-2xl overflow-hidden border border-gray-200 shadow-[0_8px_30px_rgb(0,0,0,0.12)] backdrop-blur-sm">
            <div className="p-6 sm:p-8 space-y-6">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-[#2D2D2D]">Report Results</h2>
                <div className="relative" ref={dropdownRef}>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-200 rounded-xl shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2D2D2D] transition-all duration-200"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <svg
                      className="h-5 w-5 mr-2 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    Export Report
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {isDropdownOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 border border-gray-200">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button
                          onClick={exportCSV}
                          className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-black transition duration-150 ease-in-out"
                          role="menuitem"
                        >
                          <svg
                            className="mr-3 h-5 w-5 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>
                          Export as CSV
                        </button>
                        <button
                          onClick={exportJSON}
                          className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-black transition duration-150 ease-in-out"
                          role="menuitem"
                        >
                          <svg
                            className="mr-3 h-5 w-5 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                          Export as JSON
                        </button>
                        <button
                          onClick={exportPDF}
                          className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-black transition duration-150 ease-in-out"
                          role="menuitem"
                        >
                          <svg
                            className="mr-3 h-5 w-5 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                            />
                          </svg>
                          Export as PDF
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="overflow-x-auto rounded-xl border border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#2D2D2D]">
                    <tr>
                      {[
                        "Employee ID",
                        "Name",
                        "Total Days",
                        "Working Days",
                        "Present Days",
                        "Absent Days",
                        "Leave Days",
                        "Fridays",
                        "Holidays",
                        "Late Days",
                        "Late Absences",
                        "Days With Completed Task",
                      ].map((header) => (
                        <th
                          key={header}
                          className="px-6 py-4 text-left text-xs font-semibold text-white uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.report.length > 0 ? (
                      reportData.report.map((employee, index) => (
                        <tr
                          key={employee.EmployeeID}
                          className={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } hover:bg-gray-100 transition duration-150 ease-in-out`}
                        >
                          {Object.values(employee).map((value, index) => (
                            <td key={index} className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12} className="px-6 py-4 text-center text-sm text-gray-500">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}

export default AttendanceReportSummary

